<template>
	<div>
		<pui-datatable :modelName="model" :modelColumnDefs="columnDefs" :externalFilter="externalFilter" :showDeleteBtn="true"></pui-datatable>
		<!--:actions="actions"-->
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
//import ApplicationActions from './PmApplicationActions';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmapplicationgrid',
	data() {
		return {
			model: 'pmapplication',
			//actions: ApplicationActions.actions,
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			}
		};
	},
	computed: {},
	mounted() {
		// eslint-disable-next-line no-unused-vars
	},

	created() {},

	methods: {}
};
</script>

<style lang="postcss" scoped></style>
